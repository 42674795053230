import React from "react";
import { Markdown } from "../components/Markdown";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Markdown file={"privacy"} />
        </>
    );
};

export default PrivacyPolicyPage;
