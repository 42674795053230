import React from "react";
import { Markdown } from "../components/Markdown";

const QuestionsPage = () => {
    return (
        <>
            <Markdown file={"faq"} />
        </>
    );
};

export default QuestionsPage;
